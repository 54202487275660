<template>
<div id="page" class="project-ma-in-base">
  <el-row class="pmib-form-row row-start-between">
    <el-form :model="form" class="row-center-start" ref="formRef">
      <el-form-item prop="txt_keywords">
        <el-input v-model="form.txt_keywords" placeholder="申办方名称/项目名称">
          <template #prepend>
            <i class="iconfont icon-sousuo" />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="dateRange">
        <el-date-picker value-format="YYYY-MM-DD" v-model="form.dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList()">搜索</el-button>
        <el-button type="info" @click="reset()">重置</el-button>
      </el-form-item>
    </el-form>
    <el-button type="primary" class="pmibfr-add-btn" @click="handleAdd">添加项目</el-button>
  </el-row>
  <el-table :data="table.data" v-loading="table.loading" header-cell-class-name="table-cell">
    <el-table-column prop="txt_project_code" label="项目编码" align="center" />
    <el-table-column prop="txt_project_name" label="项目名称" align="center">
      <template #default="scope">
        <template v-if="scope.row.txt_project_name.length>26">
          <el-tooltip class="box-item" effect="dark" :content="scope.row.txt_project_name.slice(0, 80)+'...'" placement="top">
            {{ scope.row.txt_project_name.slice(0, 26) + "..." }}
          </el-tooltip>
        </template>
        <template v-else>{{ scope.row.txt_project_name||"- -" }}</template>
      </template>
    </el-table-column>
    <el-table-column prop="txt_sponsor_name" label="所属申办方" align="center" />
    <el-table-column prop="is_disabled" label="项目状态" align="center">
      <!-- 1启用 0禁用 -->
      <template #default="scope">
        <template v-if="scope.row.is_disabled==1">已启用</template>
        <template v-else-if="scope.row.is_disabled==0">已禁用</template>
        <template v-else>- -</template>
      </template>
    </el-table-column>
    <el-table-column prop="dt_create" label="创建时间" align="center">
      <template #default="scope">{{ scope.row.dt_create||"- -" }}</template>
    </el-table-column>
    <el-table-column label="操作" align="center" class-name="table-option" width="340">
      <template #default="scope">
        <div class="table-icon has-margin-right" @click="handleEdit(scope.row)">编辑</div>
        <div class="table-icon has-margin-right" @click="()=>{dialog.interviewData=scope.row;dialog.interShow=true;}">访视管理</div>
        <div class="table-icon has-margin-right" @click="()=>{dialog.disData=scope.row;dialog.disShow=true;}">分配中心</div>
        <div class="table-icon has-margin-right" @click="()=>{dialog.setData=scope.row;dialog.setShow=true;}">人员配置</div>
        <div class="table-icon has-margin-right" v-if="scope.row.is_disabled==null" @click="handleDelete(scope.row)">删除</div>
        <div class="table-icon" v-if="scope.row.is_disabled==1" @click="handleUpdateAuth(scope.row, 0)">禁用</div>
        <div class="table-icon" v-if="scope.row.is_disabled==null||scope.row.is_disabled==0" @click="handleUpdateAuth(scope.row, 1)">启用</div>
      </template>
    </el-table-column>
  </el-table>
  <pagination ref="pageRef" :total="total" @sizeChange="(e) => {form.limit=e;getList()}" @pageChange="(e) => {form.page=e;getList()}" />
  <update-dialog v-if="dialog.updateShow" :data="dialog.updateData" @close="(e) => {dialog.updateShow=false;if(e==true){getList()}}" />
  <interview-ma v-if="dialog.interShow" :data="dialog.interviewData" @close="dialog.interShow=false" />
  <distribution v-if="dialog.disShow" :data="dialog.disData" @close="dialog.disShow=false" />
  <set-comp v-if="dialog.setShow" :data="dialog.setData" @close="dialog.setShow=false" />
</div>
</template>

<script>
// 基础信息管理 - 项目管理
import InterviewMa from "./comp/interview.vue";
import Distribution from "./comp/distribution.vue";
import SetComp from "./comp/set.vue";
import { ElMessage, ElMessageBox } from 'element-plus';
import { Pagination, UpdateDialog } from "components";
import { onMounted, reactive, toRefs, ref } from 'vue';
import { getProjectList, addProject, editProject, getSponsorInCommon, getProjectSponsorsByProId, getSponsorsById, updateProjectAuth, deleteProject } from "api/apis.js";
export default {
  components: {
    Pagination,
    UpdateDialog,
    InterviewMa,
    Distribution,
    SetComp
  },
  setup() {
    const pageRef = ref(null);
    const state = reactive({
      form: {
        txt_keywords: null,
        dateRange: [],
        page: 1,
        limit: 10
      },
      total: 0,
      table: {
        data: [],
        loading: false
      },
      dialog: {
        updateShow: false,
        updateData: {},
        interShow: false,
        interviewData: {},
        disShow: false,
        disData: {},
        setShow: false,
        setData: {}
      }
    })

    onMounted(() => {
      getList();
    })

    // 删除
    const handleDelete = row => {
      ElMessageBox.confirm(`删除后不可恢复，请确认是否删除项目【${row.txt_project_name}】？`, "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消"
      }).then(() => {
        state.table.loading = true;
        deleteProject({ t_id: row.t_project_id }).then(response => {
          if (response.code == 200) {
            ElMessage.success("删除成功");
            getList();
          } else {
            ElMessage.error("删除失败：" + response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.table.loading = false;
        })
      })
    };

    // 启用-1/禁用-0 
    const handleUpdateAuth = (row, type) => {
      let enableHint = `确认是否【启用】当前项目【${row.txt_project_name}】？`,
        forbiddenHint = `禁用状态下该项目不可访问，请确认是否禁用【${row.txt_project_name}】？`;
      ElMessageBox.confirm(type == 1 ? enableHint : forbiddenHint, "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消"
      }).then(() => {
        state.table.loading = true;
        updateProjectAuth({
          t_id: row.t_project_id,
          status: type
        }).then(response => {
          if (response.code == 200) {
            ElMessage.success("操作成功");
            getList();
          } else {
            ElMessage.error("操作失败，" + response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.table.loading = false;
        })
      })
    };

    // 添加项目
    const handleAdd = () => {
      state.dialog.updateData = {
        title: "添加项目",
        type: "add",
        data: [
          { key: "t_sponsor_id", needChange: getSponsorsById, message: "请选择申办方", type: "picker", hint: false, label: "所属申办方", value: "", fn: getSponsorInCommon, select: { label: "txt_sponsor_name", value: "t_sponsor_id" } },
          { key: "sponsorAdminIds", needChange: null, message: "请选择负责该项目的申办方管理员（可多选）", type: "multiple", hint: false, label: "项目负责人", value: [], select: { label: "txt_sponsor_admin_name", value: "t_sponsor_admin_id" } },
          { key: "txt_project_code", needChange: null, message: "请填写项目编码", type: "input", hint: false, label: "项目编码", value: null },
          { key: "txt_project_name", needChange: null, message: "请填写项目名称", type: "input", hint: false, label: "项目名称", value: null }
        ],
        api: addProject
      };
      state.dialog.updateShow = true;
    };

    // 编辑项目
    const handleEdit = row => {
      state.dialog.updateData = {
        title: "编辑项目 - " + row.txt_project_name,
        type: "edit",
        data: [
          { key: "t_sponsor_id", needGoOn: getProjectSponsorsByProId, needChange: getSponsorsById, message: "请选择申办方", type: "picker", hint: false, label: "所属申办方", value: row.t_sponsor_id, fn: getSponsorInCommon, select: { label: "txt_sponsor_name", value: "t_sponsor_id" } },
          { key: "sponsorAdminIds", needGoOn: null, message: "请选择负责该项目的申办方管理员（可多选）", type: "multiple", hint: false, label: "项目负责人", value: [], fn: getSponsorsById, param: { t_sponsor_id: row.t_sponsor_id }, select: { label: "txt_sponsor_admin_name", value: "t_sponsor_admin_id" } },
          { key: "txt_project_code", needGoOn: null, message: "项目编码不能为空", type: "input", hint: false, label: "项目编码", value: row.txt_project_code },
          { key: "txt_project_name", needGoOn: null, message: "项目名称不能为空", type: "input", hint: false, label: "项目名称", value: row.txt_project_name }
        ],
        t_project_id: row.t_project_id,
        api: editProject
      };
      state.dialog.updateShow = true;
    };

    // 获取列表数据
    const getList = () => {
      state.table.loading = true;
      let param = {
        txt_keywords: state.form.txt_keywords,
        dt_from: (state.form.dateRange || []).length == 0 ? null : state.form.dateRange[0],
        dt_end: (state.form.dateRange || []).length == 0 ? null : state.form.dateRange[1],
        pages: state.form.page,
        pageSize: state.form.limit
      }

      // 去掉空数据
      let keys = Object.keys(param);
      for (let i = 0; i < keys.length; i++) {
        if ((param[keys[i]] || "") == "") {
          delete param[keys[i]];
        }
      }

      getProjectList(param).then(response => {
        if (response.code == 200) {
          state.table.data = response.data;
          state.total = response.total;
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.loading = false;
      })
    };

    // 重置表单，重新搜索
    const reset = () => {
      state.form = {
        txt_keywords: null,
        dateRange: [],
        page: 1,
        limit: 10
      };
      pageRef.value.pagination.pageSize = 10;
      pageRef.value.pagination.currentPage = 1;
      getList();
    }

    return {
      ...toRefs(state),
      getList,
      reset,
      pageRef,
      handleAdd,
      handleEdit,
      handleUpdateAuth,
      handleDelete
    }
  }
}
</script>

<style lang="scss" scoped>
.project-ma-in-base {
  .pmib-form-row {
    .pmibfr-add-btn {
      border-radius: 16px;
      border-color: #8a88c3;
      background-color: #8a88c3;
    }
  }

  .el-table {
    height: calc(100% - 100px);

    .has-margin-right {
      margin-right: 6px;
    }
  }
}
</style>
