<template>
<div id="page" class="cost-processed-index">
  <el-form :model="form" ref="formRef">
    <el-row>
      <el-form-item prop="txt_filter_number">
        <el-input v-model="form.txt_filter_number" placeholder="受试者筛选号">
          <template #prepend>
            <i class="iconfont icon-sousuo" />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="dateRange">
        <el-date-picker value-format="YYYY-MM-DD" v-model="form.dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
      </el-form-item>
      <el-form-item prop="status" label="发放状态">
        <el-select v-model="form.status">
          <el-option v-for="(item, index) in statusOption" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-button class="export-btn" @click="getList()">搜索</el-button>
      <el-button type="info" @click="reset()">重置</el-button>
      <el-button class="export-btn" @click="()=>{exSearchShow=true;}">费用发放</el-button>
      <el-button :disabled="table.data.length==0" class="export-btn" @click="goExport">导出</el-button>
    </el-row>
  </el-form>
  <el-table :data="table.data" v-loading="table.loading" header-cell-class-name="table-cell">
    <el-table-column prop="txt_project_name" label="所属项目" align="center">
      <template #default="scope">
        <template v-if="scope.row.txt_project_name.length>26">
          <el-tooltip class="box-item" effect="dark" :content="scope.row.txt_project_name.slice(0, 80)+'...'" placement="top">
            {{ scope.row.txt_project_name.slice(0, 26) + "..." }}
          </el-tooltip>
        </template>
        <template v-else>{{ scope.row.txt_project_name||"- -" }}</template>
      </template>
    </el-table-column>
    <el-table-column prop="txt_sponsor_name" label="申办方名称" align="center" />
    <el-table-column prop="txt_filter_number" label="受试者筛选号" align="center">
      <template #default="scope">{{scope.row.txt_filter_number||"- -"}}</template>
    </el-table-column>
    <el-table-column prop="txt_bankcard_number" label="银行卡号" align="center">
      <template #default="scope">{{scope.row.txt_bankcard_number||"- -"}}</template>
    </el-table-column>
    <el-table-column prop="dec_compensation_amount" label="费用金额（元）" align="center">
      <template #default="scope">{{scope.row.dec_compensation_amount||"- -"}}</template>
    </el-table-column>
    <el-table-column prop="txt_status" label="发放状态" align="center">
      <template #default="scope">
        <template v-if="scope.row.txt_status==1">未发放</template>
        <template v-else-if="scope.row.txt_status==2">发放中</template>
        <template v-else-if="scope.row.txt_status==3">已发放</template>
      </template>
    </el-table-column>
    <el-table-column prop="dt_sponsor_verify" label="审核通过时间" align="center">
      <template #default="scope">{{scope.row.dt_sponsor_verify||"- -"}}</template>
    </el-table-column>
    <el-table-column prop="txt_distribution_request_no" label="业务参考号" align="center">
      <template #default="scope">{{scope.row.txt_distribution_request_no||"- -"}}</template>
    </el-table-column>
  </el-table>
  <pagination ref="pageRef" :total="table.total" @sizeChange="(e) => {form.limit=e;getList()}" @pageChange="(e) => {form.page=e;getList()}" />
  <ex-search v-if="exSearchShow" @close="(e)=>{exSearchShow=false;if(e==true){getList()}}" />
</div>
</template>

<script>
// 费用发放
import { useStore } from "vuex";
import exSearch from "./comp/exSearch.vue";
import { Pagination } from "components";
import { ElMessage } from 'element-plus';
import { reactive, toRefs, onMounted, ref, computed } from 'vue';
import { getCompensationList, exportExpenseList } from "api/apis.js";
// "status": 2, //筛选状态 2未发放 5 已发放
export default {
  components: {
    Pagination,
    exSearch
  },
  setup() {
    const pageRef = ref(null);
    const store = useStore();
    const state = reactive({
      form: {
        txt_filter_number: null,
        dateRange: [],
        status: 0,
        page: 1,
        limit: 10
      },
      exSearchShow: false,
      table: {
        total: 0,
        data: [],
        loading: false
      },
      statusOption: [
        { id: 0, name: "全部" },
        { id: 1, name: "未发放" },
        { id: 2, name: "发放中" },
        { id: 3, name: "已发放" }
      ],
      operateInfo: computed(() => store.getters.operateInfo),
      param: {}
    });

    onMounted(() => {
      getList();
    })

    // 导出
    const goExport = () => {
      state.table.loading = true;
      state.param = {
        txt_filter_number: state.form.txt_filter_number || "",
        dt_from: (state.form.dateRange || []).length == 0 ? "" : state.form.dateRange[0],
        dt_end: (state.form.dateRange || []).length == 0 ? "" : state.form.dateRange[1],
        status: state.form.status,
        pages: state.form.page,
        pageSize: state.form.limit
      };
      let keys = Object.keys(state.param);
      for (let i = 0; i < keys.length; i++) {
        if ((state.param[keys[i]] || "") == "") {
          delete state.param[keys[i]];
        }
      }
      exportExpenseList(state.param).then(response => {
        let blob = new Blob([response], { type: "application/vnd.ms-excel;charset=UTF-8" });
        let downloadElement = document.createElement('a');
        let href = window.URL.createObjectURL(blob); // 创建下载的链接
        downloadElement.href = href;
        downloadElement.download = "费用管理列表.xls"; // 下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载
        document.body.removeChild(downloadElement); // 下载完成移除元素
        window.URL.revokeObjectURL(href);
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.loading = false;
      })
    };
    // 获取列表
    const getList = () => {
      state.table.loading = true;
      state.table.data = [];
      state.param = {
        txt_filter_number: state.form.txt_filter_number,
        dt_from: (state.form.dateRange || []).length == 0 ? null : state.form.dateRange[0],
        dt_end: (state.form.dateRange || []).length == 0 ? null : state.form.dateRange[1],
        status: state.form.status,
        pages: state.form.page,
        pageSize: state.form.limit
      };
      let keys = Object.keys(state.param);
      for (let i = 0; i < keys.length; i++) {
        if ((state.param[keys[i]] || "") == "") {
          delete state.param[keys[i]];
        }
      }

      getCompensationList(state.param).then(response => {
        if (response.code == 200) {
          if (response.data) {
            state.table.data = response.data;
            state.table.total = response.total;
          } else {
            state.table.data = [];
            state.table.total = 0;
          }
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.loading = false;
      })
    };

    // 重置表单，重新搜索
    const reset = () => {
      state.form = {
        txt_filter_number: null,
        dateRange: [],
        status: "",
        page: 1,
        limit: 10
      };
      pageRef.value.pagination.pageSize = 10;
      pageRef.value.pagination.currentPage = 1;
      getList();
    }

    return {
      ...toRefs(state),
      getList,
      reset,
      goExport,
      pageRef,
    }
  }
}
</script>

<style lang="scss" scoped>
.cost-processed-index {

  .el-form {
    .el-button {
      margin-bottom: 4px;
    }
  }

  .export-btn {
    color: #ffffff;
    background-color: #8a88c3;
  }

  .el-table {
    height: calc(100% - 100px);
  }
}
</style>
