<template>
<el-dialog v-model="visible" :title="title" :before-close="close" custom-class="comp-add-or-edit-dialog">
  <el-form ref="ruleFormRef" v-loading="loading" :model="form" :rules="rules">
    <el-form-item v-for="(item, index) in form" :key="index" :label="item.label" :prop="item.key">
      <el-select v-if="item.type=='picker'" filterable v-model="item.value" :placeholder="item.message" @change="handleChange($event, item)">
        <!-- <el-option v-for="(sItem, sIndex) in item.option" :key="sIndex" :label="sItem[item.select.label]" :value="sItem[item.select.value]" /> -->
        <el-option v-for="(sItem, sIndex) in item.option" clearable :key="sIndex" :label="sItem[item.select.label]" :value="sItem[item.select.value]">
          <div v-if="sItem[item.select.label].length<14">{{ sItem[item.select.label] }}</div>
          <el-popover v-else placement="top-start" trigger="hover" :content="sItem[item.select.label]">
            <template #reference>
              <div>{{ sItem[item.select.label].slice(0, 14) + "..." }}</div>
            </template>
          </el-popover>
        </el-option>
      </el-select>
      <el-input v-else-if="item.type=='input'" v-model.trim="item.value" @input="()=>{item.value=item.value.replace(/(^\s*)|(\s*$)/g,'')}" :placeholder="item.message" />
      <el-select v-else-if="item.type=='multiple'" v-model="item.value" :placeholder="item.message" multiple @change="multiChange()">
        <el-option v-for="(sItem, sIndex) in item.option" :key="sIndex" mul :label="sItem[item.select.label]" :value="sItem[item.select.value]" />
      </el-select>
    </el-form-item>
  </el-form>
  <template #footer>
    <div class="dialog-footer">
      <el-button @click="submit(false)" type="info">取 消</el-button>
      <el-button @click="submit(true)" type="primary">确 认</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 公共组件 - 添加/编辑
import { computed, reactive, toRefs, ref, onBeforeMount } from 'vue';
import { ElMessage, FormInstance } from 'element-plus';
export default {
  props: {
    data: { type: Object },
  },
  setup(prop, ctx) {
    const state = reactive({
      visible: true,
      ruleFormRef: ref(FormInstance),
      title: computed(() => {
        if (prop.data.title.length > 24) {
          return prop.data.title.slice(0, 24) + "....";
        } else {
          return prop.data.title;
        }
      }),
      rules: computed(() => {
        let result = [];
        for (let i = 0; i < prop.data.data.length; i++) {
          let item = prop.data.data[i];
          result[item.key] = [{ required: true, validator: validate, trigger: 'blur' }];
        }
        return result;
      }),
      form: computed(() => prop.data.data.concat()),
      loading: false
    });

    // 判断表单中是否含有 picker
    // 如有，获取选项
    onBeforeMount(() => {
      // state.form = prop.data.data.concat();
      let pickerArr = prop.data.data.filter(t => t.type == "picker" || t.type == "multiple");
      if (pickerArr.length > 0) {
        for (let i = 0; i < pickerArr.length; i++) {
          getOptions(pickerArr[i].fn, pickerArr[i].key, pickerArr[i].param, pickerArr[i]);
        }
      }
    })

    // todo 编辑时，获取已分配的项目负责人，缺接口和逻辑函数 暂存
    // 多选框切换事件
    const multiChange = () => {};

    // select 切换事件
    const handleChange = (e, item) => {
      if (item.needChange) {
        let param = {};
        param[item.key] = e;
        let index = state.form.findIndex(t => t.type == "multiple");
        item.needChange(param).then(response => {
          if (response.code == 200) {
            if (response.data) {
              state.form[index].option = response.data;
            } else {
              state.form[index].option = [];
              state.form[index].value = [];
            }
          } else {
            state.form[index].option = [];
            state.form[index].value = [];
          }
        }).catch(e => {
          console.error(e);
        })
      }
    };

    // 获取 picker 选项
    const getOptions = (fn, key, param, picker) => {
      if (fn) {
        let index = state.form.findIndex(t => t.key == key);
        fn(param).then(response => {
          if (response.code == 200) {
            state.form[index].option = response.data;
            if (picker.needGoOn) {
              // 项目管理 - 编辑项目单独的，如后期有其他的，再重构函数
              let req = { t_project_id: prop.data.t_project_id };
              picker.needGoOn(req).then(res => {
                if (res.code == 200) {
                  if (res.data) {
                    let targetIndex = state.form.findIndex(t => t.type == "multiple");
                    state.form[targetIndex].oldValue = res.data.map(t => {
                      return {
                        oldType: "4",
                        t_sponsor_admin_id: t.t_sponsor_admin_id,
                        t_sponsor_admin_project_id: t.t_sponsor_admin_project_id
                      }
                    });
                    state.form[targetIndex].value = res.data.map(t => t[state.form[targetIndex].select.value]);
                  }
                }
              })
            }
            state.visible = true;
          } else {
            state.form[index] = [];
            state.visible = false;
            ElMessage.error(response.msg);
          }
        }).catch(e => {
          console.error(e);
        })
      }
    };

    // 自定义 校验规则
    const validate = (rule, value, callback) => {
      let key = rule.field,
        index = state.form.findIndex(t => t.key == key);

      // 手机号单独判断
      if (key == "txt_mobile") {
        if (state.form[index].hint.test(state.form[index].value)) {
          callback();
        } else {
          callback("手机号格式不正确");
        }
      } else {
        if ((state.form[index].value || "") == "") {
          callback(new Error(state.form[index].label + "不能为空"));
        } else {
          callback();
        }
      }
    };

    // 添加申办方
    const submit = type => {
      // 提交 表单校验
      if (type) {
        state.ruleFormRef.validate(valid => {
          // 校验通过 走提交接口
          if (valid) {
            state.loading = true;
            let param = {};
            for (let i = 0; i < prop.data.data.length; i++) {
              let item = prop.data.data[i];
              // 项目管理，单独判断
              //类型 1 新增 2删除 3编辑
              if (prop.data.type == "add") {
                if (item.key == "sponsorAdminIds") {
                  param['savePMProjectDTOList'] = item.value.map(t => {
                    return {
                      t_sponsor_admin_id: t,
                      type: "1"
                    }
                  })
                } else {
                  param[item.key] = item.value;
                }
              } else {
                if (item.key == "sponsorAdminIds") {
                  let resParam = [];
                  // 如果 存在旧数据
                  if (item.oldValue) {
                    // 循环旧数据
                    for (let j = 0; j < item.oldValue.length; j++) {
                      // 在新数据中查看是否含有旧数据
                      let resIndex = item.value.findIndex(t => t == item.oldValue[j].t_sponsor_admin_id);
                      //类型 1 新增 2删除 3编辑
                      // 新数据中不含旧数据，type = "2" 删除
                      if (resIndex == -1) {
                        resParam.push({ t_sponsor_admin_id: item.oldValue[j].t_sponsor_admin_id, t_sponsor_admin_project_id: item.oldValue[j].t_sponsor_admin_project_id, type: "2" });
                      } else {
                        // 新数据中含有旧数据，暂时按 编辑 类型 传
                        resParam.push({ t_sponsor_admin_id: item.oldValue[j].t_sponsor_admin_id, t_sponsor_admin_project_id: item.oldValue[j].t_sponsor_admin_project_id, type: "3" });
                        // 同时，按索引 删掉 新数据 对应元素
                        item.value.splice(resIndex, 1);
                      }
                    }
                    // 旧数据循环结束后，查看新数据中 是否含有遗漏的数据
                    if (item.value.length > 0) {
                      for (let z = 0; z < item.value.length; z++) {
                        resParam.push({ t_sponsor_admin_id: item.value[z], type: "1" });
                      }
                    }
                  } else {
                    // 没有旧数据
                    // 循环新数据，新选择的数据格式化
                    for (let z = 0; z < item.value.length; z++) {
                      resParam.push({ t_sponsor_admin_id: item.value[z], type: "1" });
                    }
                  }
                  param['savePMProjectDTOList'] = resParam;
                } else {
                  param[item.key] = item.value;
                }
              }
              // if (prop.data.type == "add" && item.key == "sponsorAdminIds") {
              //   param['savePMProjectDTOList'] = item.value.map(t => {
              //     return {
              //       t_sponsor_admin_id: t,
              //       type: "1"
              //     }
              //   })
              // } else {
              //   if (prop.data.type == "edit" && item.key == "sponsorAdminIds") {
              //     let resParam = [];
              //     // 循环旧数据
              //     for (let j = 0; j < item.oldValue.length; j++) {
              //       // 在新数据中查看是否含有旧数据
              //       let resIndex = item.value.findIndex(t => t == item.oldValue[j].t_sponsor_admin_id);
              //       //类型 1 新增 2删除 3编辑
              //       // 新数据中不含旧数据，type = "2" 删除
              //       if (resIndex == -1) {
              //         resParam.push({ t_sponsor_admin_id: item.oldValue[j].t_sponsor_admin_id, t_sponsor_admin_project_id: item.oldValue[j].t_sponsor_admin_project_id, type: "2" });
              //       } else {
              //         // 新数据中含有旧数据，暂时按 编辑 类型 传
              //         resParam.push({ t_sponsor_admin_id: item.oldValue[j].t_sponsor_admin_id, t_sponsor_admin_project_id: item.oldValue[j].t_sponsor_admin_project_id, type: "3" });
              //         // 同时，按索引 删掉 新数据 对应元素
              //         item.value.splice(resIndex, 1);
              //       }
              //     }
              //     // 旧数据循环结束后，查看新数据中 是否含有遗漏的数据
              //     if (item.value.length > 0) {
              //       for (let z = 0; z < item.value.length; z++) {
              //         resParam.push({ t_sponsor_admin_id: item.value[z], type: "1" });
              //       }
              //     }
              //     param['savePMProjectDTOList'] = resParam;
              //   } else {
              //     param[item.key] = item.value;
              //   }
              // }
            }
            // 编辑 类型单独判断，请求参数添加对应 "id" 字段
            if (prop.data.type == "edit") {
              let keys = Object.keys(prop.data),
                index = keys.findIndex(t => t.includes("_id"));
              param[keys[index]] = prop.data[keys[index]];
            }

            prop.data.api(param).then(response => {
              if (response.code == 200) {
                ElMessage.success("操作成功");
                ctx.emit("close", true);
              } else {
                ElMessage.error(response.msg);
              }
            }).catch(e => {
              console.error(e);
            }).finally(() => {
              state.loading = false;
            })
          }
        })
      } else {
        // 仅关闭
        ctx.emit("close", false);
      }
    };

    const close = () => {
      ctx.emit("close", false);
    }

    return {
      ...toRefs(state),
      close,
      submit,
      validate,
      getOptions,
      handleChange,
      multiChange
    }
  }
}
</script>

<style lang="scss">
.el-overlay-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.comp-add-or-edit-dialog {
  width: 650px !important;
  height: 60vh !important;

  .el-form-item__label {
    width: 100px;
    text-align: left;
  }

  .el-form-item__content {
    .el-select {
      width: 100%;
    }
  }

  .dialog-footer {
    .el-button {
      border-radius: 16px;

      &:last-child {
        border-color: #8a88c3;
        background-color: #8a88c3;
      }
    }
  }
}
</style>
